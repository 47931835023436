var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("运维工程师")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("和我们，做有挑战的事！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("开发")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 社会招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 主要负责内部项目的运营和维护； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 负责公司多产品线的客户技术支持工作，在技术层面和客户进行沟通，及时解决问题，维护项目高效正常运行。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 计算机及相关专业毕业，本科以上学历，1-3年及以上相关工作经验，熟悉c/cpp/java等开发语言； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 具有良好的学习能力、沟通能力、服务理念和合作精神； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 强烈的责任心与主动性，对所负责工作有owner意识，并能自我驱动成长。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")]),_c('span',[_vm._v(" 徐州 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }